import {
  Button,
  Form,
  Input,
  Message,
  Typography,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { Grid } from "@arco-design/web-react";
import { Logo } from "../components/Logo";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/userSlice";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
} from "../utils/cssVariables";
import { RootState } from "../store/store";
import { useEffect } from "react";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;

export const Login = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!!user && user.loggedIn) {
      navigate(routes.DASHBOARD);
    }
  }, [navigate, user]);

  const onSubmit = async () => {
    if (form) {
      form.validate().then(() => {
        axios
          .post(endpoints.login, form.getFields())
          .then((response) => {
            const userData = response.data;

            dispatch(
              setUser({
                loggedIn: true,
                userID: userData.userID,
                name: userData.name,
                email: userData.email,
                isMentor: userData.isMentor,
              })
            );

            // Navigate to the dashboard
            navigate(routes.DASHBOARD);
            Message.success("You have successfully logged in!");
          })
          .catch((error) => {
            console.error(error);
            Message.error(
              "Oops! Something went wrong, please check your credentials and try again."
            );
          })
          .finally(() => {});
      });
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",
        backgroundColor: boxBackgroundColor,
        maxWidth: 480,
        width: "calc(100% - 40px)",
        margin: "0 auto",
        padding: "20px",
        borderRadius: 8,
      }}
    >
      <Logo
        size={350}
        style={{
          marginTop: 10,
          maxWidth: "100%",
        }}
      />
      <Form onSubmit={onSubmit} form={form}>
        <Row justify="center">
          <div
            style={{
              padding: "20px",
              width: "calc(100% - 40px)",
              maxWidth: 350,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <Typography.Text
                style={{ fontSize: 32, color: colorAccent1 }}
                type="success"
                className={"alice-regular"}
              >
                Welcome
              </Typography.Text>
            </div>
            <div style={{ marginTop: 40 }}>
              <FormItem
                id="email"
                field="email"
                layout="vertical"
                label="Email"
              >
                <Input placeholder="Enter your email" />
              </FormItem>
              <FormItem
                id="password"
                field="password"
                layout="vertical"
                label="Password"
              >
                <Input.Password type="password" placeholder="Enter your password" />
              </FormItem>
              <Button
                htmlType="submit"
                style={{
                  color: buttonTextColor,
                  backgroundColor: buttonColor,
                  width: "100%",
                }}
                size={"large"}
                shape={"round"}
              >
                Sign in
              </Button>
            </div>
          </div>
        </Row>
      </Form>

      <div style={{ textAlign: "center", marginTop: 60 }}>
        <Typography.Paragraph>
          Don't have an account?
          <NavLink to={routes.REGISTER}>
            <Button
              style={{ padding: "0 0 0 4px" }}
              type="text"
              status="success"
            >
              <span style={{ color: colorAccent1 }}>Sign up</span>
            </Button>
          </NavLink>
          <br />
          Forgot your password?
          <NavLink to={routes.RESETPASSWORD}>
            <Button
              style={{ padding: "0 0 0 4px" }}
              type="text"
              status="success"
            >
              <span style={{ color: colorAccent1 }}>Reset password</span>
            </Button>
          </NavLink>
        </Typography.Paragraph>
      </div>
    </div>
  );
};
