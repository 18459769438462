import {
  Form,
  Grid,
  Input,
  Button,
  Message,
  Tooltip,
  Typography,
  VerificationCode,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { Logo } from "../components/Logo";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
} from "../utils/cssVariables";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const Row = Grid.Row;
const FormItem = Form.Item;
const PasswordComplexityTooltip = (
  <div>
    <Typography.Paragraph
        style={{ fontSize: 12, color: "white", marginBottom: 0, whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        spacing="close"
    >
      Password must be at least 8 characters long and must contain at least:
      <ul>
        <li>one lowercase letter,</li>
        <li>one uppercase letter,</li>
        <li>one digit,</li>
        <li>and one special character.</li>
      </ul>
    </Typography.Paragraph>
  </div>
)

export const ResetPassword = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState<boolean>(false);
  const [isPasswordTooltipVisible, setIsPasswordTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!!user && user.loggedIn) {
      navigate(routes.DASHBOARD);
    }
  }, [navigate, user]);

  const sendEmailWithOtpCode = (formEmail: string | undefined = undefined) => {
    axios
      .post(endpoints.recoverAccount, {
        "email": form.getFieldValue("email"),
      })
      .then(() => {
        if (!formEmail) {
          Message.success("A new verification code has been resent to your email.");
        }
      })
      .catch((error) => {
        console.error(error);
        Message.error("Something went wrong. Please try again later.");
      })
      .finally(() => {});
  }

  const setNewPassword = () => {
    axios
      .post(endpoints.resetPassword, {
        "email": form.getFieldValue("email"),
        "newPassword": form.getFieldValue("password"),
        "otp": form.getFieldValue("otp"),
      })
      .then(() => {
        // Navigate to the login page
        navigate(routes.LOGIN);
        Message.success("You have successfully reset your password! You can now use your new password to log in.");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setIsOtpInvalid((isOtpInvalid) => (isOtpInvalid = true));
        }
        else {
          console.error(error);
          Message.error("Something went wrong. Please try again later.");
        }
      })
      .finally(() => {});
  }

  const onSubmit = async () => {
    if (!isEmailSent) {
      setIsEmailSent((isEmailSent) => (isEmailSent = true));
      form.validate().then(sendEmailWithOtpCode);
    }
    else {
      form.validate().then(setNewPassword);
    }
  };

  return (
      <div
        style={{
          justifyContent: "center",
          backgroundColor: boxBackgroundColor,
          maxWidth: 480,
          width: "calc(100% - 40px)",
          margin: "0 auto",
          padding: "20px",
          borderRadius: 8,
        }}
      >
        <Logo
          size={350}
          style={{
            marginTop: 10,
            maxWidth: "100%",
          }}
        />
        <Form onSubmit={onSubmit} form={form}>
          <Row justify="center">
            <div
              style={{
                padding: "20px",
                width: "calc(100% - 40px)",
                maxWidth: 350,
              }}
            >
              <div style={{marginTop: 10}}>
                <Typography.Text
                  style={{fontSize: 20, color: colorAccent1, wordBreak: "break-word"}}
                  type="success"
                  className={"alice-regular"}
                >
                  {!isEmailSent ? "Trouble logging in?" : "Reset password"}
                </Typography.Text>
              </div>
              <div style={{marginTop: 30}}>
                {!isEmailSent ? (
                  <Typography.Paragraph style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    Enter your account's email and we'll send you an email to reset the password.
                  </Typography.Paragraph>
                ) : (
                  <Typography.Paragraph style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    If an account is associated with the provided email address, a one-time verification code has been
                    sent.
                    <br/>
                    Please, type in your new password and the code from the email.
                  </Typography.Paragraph>
                )}
              </div>
              <div style={{marginTop: 20}}>
                <FormItem
                  layout="vertical"
                  label="Email"
                  field="email"
                  disabled={isEmailSent}
                  rules={!isEmailSent ? [
                    {
                      validator: (v, cb) => {
                        return !v ? cb("Email is required")
                          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ? cb("Invalid email address")
                          : cb();
                      }
                    }
                  ] : []}
                  validateTrigger="onBlur"
                >
                  <Input
                    type="email"
                    placeholder="name@example.com"
                  />
                </FormItem>
                {!isEmailSent ? (
                  <Button
                    htmlType="submit"
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonColor,
                      width: "100%",
                      marginTop: 20,
                    }}
                    size={"large"}
                    shape={"round"}
                  >
                    Send email
                  </Button>
                ) : (
                  <div>
                    <Tooltip
                      position="rt"
                      popupVisible={isPasswordTooltipVisible}
                      content={PasswordComplexityTooltip}
                    >
                      <FormItem
                        layout="vertical"
                        label="New password"
                        field="password"
                        rules={[
                          {
                            validator: (v, cb) => {
                              return !v ? cb("Password is required")
                                : v.length < 8
                                  || !/[a-z]/.test(v)
                                  || !/[A-Z]/.test(v)
                                  || !/[0-9]/.test(v)
                                  || !/[!@#$%^&*]/.test(v) ? cb("Password is too weak")
                                : cb();
                            },
                          }
                        ]}
                        validateTrigger="onChange"
                      >
                        <Input.Password
                          placeholder="Enter your new password"
                          autoComplete={"off"}
                          onFocus={() => setIsPasswordTooltipVisible(
                              (isPasswordTooltipVisible) => (isPasswordTooltipVisible = true)
                          )}
                          onBlur={() => setIsPasswordTooltipVisible(
                              (isPasswordTooltipVisible) => (isPasswordTooltipVisible = false)
                          )}
                        />
                      </FormItem>
                    </Tooltip>
                    <FormItem
                      layout="vertical"
                      label="Confirm password"
                      field="passwordConfirmation"
                      rules={[
                        {
                          validator: (v, cb) => {
                            return v !== form.getFieldValue("password") ? cb("Passwords do not match") : cb();
                          }
                        }
                      ]}
                      validateTrigger="onChange"
                    >
                      <Input.Password
                        placeholder="Confirm your new password"
                        autoComplete={"off"}
                      />
                    </FormItem>
                    <Form.Item
                      layout="vertical"
                      label="Verification code"
                      field="otp"
                      validateStatus={isOtpInvalid ? "error" : undefined}
                      rules={[
                        {
                          validator: (v, cb) => {
                            return !v || v.length < 6 ? cb("Verification code is required") : cb();
                          },
                          validateTrigger: [],
                        },
                      ]}
                      style={{ marginBottom: isOtpInvalid ? 0 : 20 }}
                    >
                      <VerificationCode
                        size="large"
                        validate={({ inputValue }) => {
                          return /^[0-9]*$/.test(inputValue) ? inputValue : false;
                        }}
                      />
                    </Form.Item>
                    {isOtpInvalid && (
                        <Typography.Text
                            type="error"
                            className={["acro-form-message", "formblink-appear-done", "formblink-enter-done"]}
                            style={{ fontSize: 12, whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                        >
                            Verification code is invalid, has expired, or the account doesn't exist. Please try again.
                        </Typography.Text>
                    )}
                    <FormItem layout="vertical" style={{ marginTop: 10, marginBottom: 0 }}>
                      <Button
                        onClick={() => {
                          form.resetFields();
                          setIsEmailSent((isEmailSent) => (isEmailSent = false));
                        }}
                        size={"large"}
                        shape={"round"}
                      >
                        Use a different email
                      </Button>
                      <Button
                        htmlType="submit"
                        style={{
                          marginLeft: 24,
                          color: buttonTextColor,
                          backgroundColor: buttonColor,
                        }}
                        size={"large"}
                        shape={"round"}
                      >
                        Reset password
                      </Button>
                    </FormItem>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Form>

        <div style={{textAlign: "center", marginTop: 20}}>
          <Typography.Paragraph>
            {isOtpInvalid && (
              <div>
                Code has expired?
                <Button
                    onClick={() => {
                      sendEmailWithOtpCode();
                      form.setFieldValue("otp", "");
                      setIsOtpInvalid((isOtpInvalid) => (isOtpInvalid = false));
                    }}
                    style={{padding: "0 0 0 4px"}}
                    type="text"
                    status="success"
                >
                  <span style={{color: colorAccent1}}>Resend code</span>
                </Button>
              </div>
            )}
            Remember your credentials?
            <NavLink to={routes.LOGIN}>
              <Button
                  style={{padding: "0 0 0 4px"}}
                  type="text"
                  status="success"
              >
                <span style={{color: colorAccent1}}>Back to login</span>
              </Button>
            </NavLink>
          </Typography.Paragraph>
        </div>
      </div>
  );
};
